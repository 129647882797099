import { createSlice } from "@reduxjs/toolkit";
import { convertFlatListToTreeList } from "common/helpers";
import { globalActions } from "../actions";

const initialState = {
	drawer: { productsPage: { show: false } },
	items: { list: [], dict: {}, tree: [] },
	filter: { selectedItemsIdList: [] },
};

const productCategorySlice = createSlice({
	name: "productCategory",
	initialState,
	reducers: {
		setProductCategory: (state, action) => {
			const itemsAsList = action.payload;
			state.items.list = itemsAsList;
			for (let i = 0; i < itemsAsList.length; i++) {
				const item = itemsAsList[i];
				state.items.dict[item.id] = item;
			}
			state.items.tree = convertFlatListToTreeList({
				flatList: itemsAsList.map((item) => {
					let itemCopy = { ...item };
					itemCopy.id = item.id.toString();
					itemCopy.parent = item.parent && item.parent.toString();

					return itemCopy;
				}),
			});
		},
		setProductCategoriesFilter: (state, action) => {
			state.filter.selectedItemsIdList = action.payload;
		},
		setShowProductsPageProductCategoryDrawer: (state, action) => {
			state.drawer.productsPage.show = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(globalActions.logout, () => initialState);
	},
});

const getProductCategoryItemsAsList = (state) => state.productCategory.items.list;
const getProductCategoryItemsAsDict = (state) => state.productCategory.items.dict;
const getProductCategoryItemsAsTree = (state) => state.productCategory.items.tree;

const getProductCategoriesFilterAsIdList = (state) =>
	state.productCategory.filter.selectedItemsIdList;

const getShowProductsPageProductCategoryDrawer = (state) =>
	state.productCategory.drawer.productsPage.show;

export {
	getProductCategoryItemsAsList,
	getProductCategoryItemsAsDict,
	getProductCategoryItemsAsTree,
	getProductCategoriesFilterAsIdList,
	getShowProductsPageProductCategoryDrawer,
};

export default productCategorySlice;
