// App Theme - MUI

import { createTheme } from "custom_exports/material_ui_items";
import { THEME } from "app/settings/settings";

function getTheme({ theme }) {
	theme ||= "default";
	if (theme === "default") {
		theme = createTheme({
			palette: THEME.app.colors,
			breakpoints: THEME.app.screen_breakpoints,
			components: {
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								borderColor: "#000000",
							},
							"&.Mui-error .MuiOutlinedInput-notchedOutline": {
								borderColor: "#d32f2f",
							},
						},
					},
				},
				MuiInputLabel: {
					styleOverrides: {
						root: {
							"&.Mui-focused": {
								color: "#000000",
							},
							"&.Mui-error": {
								color: "#d32f2f",
							},
						},
					},
				},
			},
		});
	}

	return theme;
}

export { getTheme };
