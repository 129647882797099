import { Formik } from "formik";
import YUP from "custom_exports/yup_items";
import { Box, TextField, Button } from "custom_exports/material_ui_items";
import { useLocation } from "react-router-dom";

function SignUpForm(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initialization ---- //
	// ---- destructuring ---- //
	const { handleForm, formRef } = props;

	// ---- assignments ---- //
	const location = useLocation();
	const mobileCountryCode = "+1";
	const formInitialValues = {
		email: location.state?.email || "",
		businessEmail: "",
		businessName: "",
		businessPhone: "",
		businessAddress: "",
	};

	const formValidationSchema = YUP.object().shape({
		email: YUP.string()
			.email("Provide a valid email")
			.required("Email required"),
		businessEmail: YUP.string()
			.email("Provide a valid email")
			.required("Business email required"),
		businessName: YUP.string()
			.required("Business name required")
			.max(100, "Business name must be within 100 characters"),
		businessPhone: YUP.string()
			.required("Phone number required")
			.matches(
				/^(?:\+[0-9]{11,12}|[0-9]{10})$/,
				"Provide a valid phone number"
			),
		businessAddress: YUP.string()
			.required("Address required")
			.max(500, "Address must be within 500 characters"),
	});

	//--------------------------------- functions ---------------------------------//
	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//
	const element = (
		<Formik
			initialValues={formInitialValues}
			validationSchema={formValidationSchema}
			innerRef={formRef}
			onSubmit={(userInput) => {
				handleForm({
					type: "submit",
					data: {
						email: userInput.email,
						business_name: userInput.businessName,
						business_email: userInput.businessEmail,
						business_address: userInput.businessAddress,
						business_phone: userInput.businessPhone.startsWith("+")
							? userInput.businessPhone
							: mobileCountryCode + userInput.businessPhone,
					},
				});
			}}
		>
			{({
				values,
				errors,
				touched,
				// isSubmitting,
				handleBlur,
				handleChange,
				handleSubmit,
			}) => {
				return (
					<Box sx={{ mt: 1, width: "100%" }}>
						<TextField
							id="signup-form-login-email"
							type="email"
							name="email"
							label={errors.email || "Email"}
							autoComplete="email"
							// autoFocus
							required
							margin="normal"
							fullWidth
							value={values.email}
							error={Boolean(errors.email && touched.email)}
							onBlur={handleBlur}
							onChange={(event) => {
								handleChange(event);
								handleForm({
									type: "change",
									event,
								});
							}}
						/>
						<TextField
							id="signup-form-business-email"
							type="email"
							name="businessEmail"
							label={errors.businessEmail || "Business Email"}
							autoComplete="email"
							required
							margin="normal"
							fullWidth
							value={values.businessEmail}
							error={Boolean(
								errors.businessEmail && touched.businessEmail
							)}
							onBlur={handleBlur}
							onChange={(event) => {
								handleChange(event);
								handleForm({
									type: "change",
									event,
								});
							}}
						/>
						<TextField
							id="signup-form-business-name"
							type="text"
							name="businessName"
							label={errors.businessName || "Business Name"}
							required
							margin="normal"
							fullWidth
							value={values.businessName}
							error={Boolean(
								errors.businessName && touched.businessName
							)}
							onBlur={handleBlur}
							onChange={(event) => {
								handleChange(event);
								handleForm({
									type: "change",
									event,
								});
							}}
						/>
						<TextField
							id="signup-form-business-phone"
							type="text"
							name="businessPhone"
							label={errors.businessPhone || "Phone Number"}
							required
							margin="normal"
							fullWidth
							value={values.businessPhone}
							error={Boolean(
								errors.businessPhone && touched.businessPhone
							)}
							onBlur={handleBlur}
							onChange={(event) => {
								handleChange(event);
								handleForm({
									type: "change",
									event,
								});
							}}
						/>
						<TextField
							id="signup-form-business-address"
							type="text"
							name="businessAddress"
							label={errors.businessAddress || "Address"}
							required
							margin="normal"
							fullWidth
							multiline
							rows={2}
							value={values.businessAddress}
							error={Boolean(
								errors.businessAddress && touched.businessAddress
							)}
							onBlur={handleBlur}
							onChange={(event) => {
								handleChange(event);
								handleForm({
									type: "change",
									event,
								});
							}}
						/>
					</Box>
				);
			}}
		</Formik>
	);

	return element;
}

export default SignUpForm;
