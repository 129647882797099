import { createSlice } from "@reduxjs/toolkit";
import { convertDictItemsToList } from "common/helpers";
import { globalActions } from "../actions";

const initialState = {
	cartDrawer: { show: false },
	items: { dict: {}, list: [] },
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		toggleCartDrawer: (state, action) => {
			const currentState = state.cartDrawer.show;
			const show = action.payload?.show;
			if (show === true || show === false) {
				state.cartDrawer.show = show;
			} else {
				state.cartDrawer.show = !currentState;
			}
		},
		setCart: (state, action) => {
			const items = action.payload || [];
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				const { itemId, action, count } = item;
				if (itemId in state.items.dict) {
					if (action === "add") {
						state.items.dict[itemId].quantity += count || 1;
					} else if (action === "remove") {
						if (count) {
							state.items.dict[itemId].quantity -= count;
						} else {
							delete state.items.dict[itemId];
						}
					}

					if (state.items.dict[itemId]?.quantity <= 0) {
						delete state.items.dict[itemId];
					}
				} else {
					if (action === "add") {
						state.items.dict[itemId] = {
							id: itemId,
							quantity: count || 1,
						};
					}
				}
			}
			state.items.list = convertDictItemsToList(state.items.dict);
		},
		addCartItems: (state, action) => {
			state.items.dict = { ...state.items.dict, ...action.payload };
			state.items.list = convertDictItemsToList(state.items.dict);
		},
		removeCartItems: (state, action) => {
			delete state.items.dict[action.payload];
			state.items.list = convertDictItemsToList(state.items.dict);
		},
		resetCart: (state, action) => {
			state.items = { dict: {}, list: [] };
			state.cartDrawer = { show: false };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(globalActions.logout, () => initialState);
	},
});

const getCartDrawerShowStatus = (state) => state.cart.cartDrawer.show;

const getCartItemsAsDict = (state) => state.cart.items.dict;

const getCartItemsAsList = (state) => state.cart.items.list;

const getTotalNumberOfCartItems = (state) => {
	return Object.keys(state.cart.items.dict).length;
};

export {
	getCartDrawerShowStatus,
	getCartItemsAsDict,
	getCartItemsAsList,
	getTotalNumberOfCartItems,
};

export default cartSlice;
