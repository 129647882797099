// Authentication Based Routing

import { Navigate, Outlet } from "react-router-dom";
import {
	useSelector,
	useLocation,
	useDispatch,
	useRUserMutation,
} from "custom_exports/all_hooks";
import { authActions, authSelectors } from "services/redux/reducers";
import ShowStatus from "components/common/ShowStatus";
import { callApi } from "common/api_handlers/api_call_manager";
import K from "common/keywords";
import { useCallback, useEffect } from "react";
import logout from "utils/logout";

function RequireAuthentication(props) {
	//--------------------------------- variables ---------------------------------//
	const { children } = props;

	const dispatch = useDispatch();
	const currentLocation = useLocation();

	const [RUserApiCaller, RUserApiResult] = useRUserMutation();

	const user = useSelector(authSelectors.getUser);
	const userLoggedIn = useSelector(authSelectors.getLoggedIn);

	//--------------------------------- functions ---------------------------------//
	// ---- api managers ---- //
	const onApiResponse = useCallback(
		({ apiCallerId, ...response }) => {
			// console.log(response);
			const { data } = response;
			if (apiCallerId === K.RUser) {
				const user = data.users.item;

				dispatch(authActions.setAuth({ user }));
			}
		},
		[dispatch]
	);

	const onApiError = useCallback(
		({ apiCallerId, ...error }) => {
			if (apiCallerId === K.RUser) {
				logout({ dispatch });
			}
		},
		[dispatch]
	);

	const callRUserApi = useCallback(
		(apiInputParams = {}, apiCallerId) => {
			callApi({
				apiCallerId: apiCallerId ?? K.RUser,
				apiCaller: RUserApiCaller,
				apiInputParams,
				apiActions: { onApiResponse, onApiError },
			});
		},
		[RUserApiCaller, onApiResponse, onApiError]
	);

	useEffect(() => {
		if (!user && userLoggedIn) {
			callRUserApi();
		}
	}, [callRUserApi, user, userLoggedIn]);

	// alert(userLoggedIn);

	//--------------------------------- contents ----------------------------------//
	let element = null;

	const loadingIndication = (
		<ShowStatus
			statusType="loading"
			extraSX={{ bgcolor: "white", zIndex: 10000 }}
		/>
	);

	const navigateToLoginPage = (
		<Navigate to="/auth/sign-in" state={{ from: currentLocation }} replace />
	);

	if (userLoggedIn) {
		element = (
			<>
				{!user && loadingIndication}
				{user && children}
			</>
		);
	} else {
		element = navigateToLoginPage;
	}

	return element;
}

export default RequireAuthentication;
