import { createSlice } from "@reduxjs/toolkit";
import { globalActions } from "../actions";

const initialState = {
	items: { dict: {}, list: [] },
	requireRefresh: false,
	pagination: { totalPages: 1, totalRecords: 0, pageNumber: 1, pageSize: 0 },
};

const orderSlice = createSlice({
	name: "order",
	initialState,
	reducers: {
		setOrder: (state, action) => {
			const pagination =
				action.payload?.pagination || initialState.pagination;
			const itemsAsList = action.payload?.data || [];
			state.items.list = itemsAsList;
			for (let i = 0; i < itemsAsList.length; i++) {
				const item = { ...itemsAsList[i], listPosition: i };
				state.items.dict[item.id] = item;
			}
			state.pagination = {
				totalPages: pagination.total_pages,
				totalRecords: pagination.total_records,
				pageNumber: pagination.page_number,
				pageSize: pagination.page_size,
			};
		},
		updateOrder: (state, action) => {
			const payload = action.payload;
			if (payload instanceof Array) {
				const items = payload;
				items.forEach((item) => {
					if (item.id in state.items.dict) {
						const itemListPosition =
							state.items.dict[item.id].listPosition;
						state.items.list[itemListPosition] = item;
						state.items.dict[item.id] = {
							...item,
							listPosition: itemListPosition,
						};
					}
				});
			} else {
				const item = payload;
				if (item.id in state.items.dict) {
					const itemListPosition = state.items.dict[item.id].listPosition;
					state.items.list[itemListPosition] = item;
					state.items.dict[item.id] = {
						...item,
						listPosition: itemListPosition,
					};
				}
			}
		},
		createOrder: (state, action) => {
			// const payload = action.payload;
			// if (state.items.list.length > 0) {
			// 	if (payload instanceof Array) {
			// 		const items = payload;
			// 		items.forEach((item) => {
			// 			if (!(item.id in state.items.dict)) {
			// 				const itemListPosition =
			// 					state.items.list.push(item) - 1;
			// 				state.items.dict[item.id] = {
			// 					...item,
			// 					listPosition: itemListPosition,
			// 				};
			// 			}
			// 		});
			// 	} else {
			// 		const item = payload;
			// 		if (!(item.id in state.items.dict)) {
			// 			const itemListPosition = state.items.list.push(item) - 1;
			// 			state.items.dict[item.id] = {
			// 				...item,
			// 				listPosition: itemListPosition,
			// 			};
			// 		}
			// 	}
			// } //so that will auto refresh
		},
		resetOrder: (state) => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(globalActions.logout, () => initialState);
	},
});

const getOrderItemsAsDict = (state) => state.order.items.dict;

const getOrderItemsAsList = (state) => state.order.items.list;

const getOrderRequireRefresh = (state) => state.order.requireRefresh;

const getPagination = (state) => state.order.pagination;

export {
	getOrderItemsAsDict,
	getOrderItemsAsList,
	getOrderRequireRefresh,
	getPagination,
};

export default orderSlice;
