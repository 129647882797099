import {
	Table as MUITable,
	TableContainer,
	TableBody,
	Card,
	alpha,
} from "components/common/mui";
import TableHead from "./head/TableHead";
import TableRow from "./row/TableRow";
import TablePagination from "./pagination/TablePagination";
import { useTable } from "hooks";

function Table(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const {
		data: tableData,
		dense,
		columns,
		defaultOrderBy,
		showSl,
		enableRowSelection,
		mode,
		RenderAccordion,
	} = props;
	// ---- assignments ---- //
	const table = useTable({ defaultOrderBy });

	const HEADER_COLUMNS = [
		...(showSl ? [{ id: "sl", label: "SL" }] : []),
		...columns.map((column) => ({
			id: column.key,
			label: column.header.label,
			...(column.cell?.style ? { style: column.cell.style } : {}),
		})),
		...(mode === "accordion" ? [{ id: "accordion", label: "" }] : []),
	];

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //

	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//
	const element = (
		<Card
			sx={{
				position: "relative",
				zIndex: 0,
				boxShadow: `0 0 2px 0 ${alpha(
					"#919EAB",
					0.2
				)}, 0 12px 24px -4px ${alpha("#919EAB", 0.12)}`,
			}}
			style={{ borderRadius: 16 }}
		>
			<TableContainer sx={{ position: "relative" }}>
				<MUITable
					size={table.dense ? "small" : "medium"}
					sx={{ minWidth: 800 }}
				>
					<TableHead
						enableRowSelection={enableRowSelection}
						order={"asc"}
						orderBy={table.orderBy}
						headLabel={HEADER_COLUMNS}
						rowCount={tableData.length}
						numSelected={table.selected.length}
						// onSort={table.onSort}
						onSelectAllRows={(checked) =>
							table.onSelectAllRows(
								checked,
								tableData.map((row) => row.id)
							)
						}
					/>
					<TableBody>
						{tableData
							// .slice(
							// 	table.page * table.rowsPerPage,
							// 	table.page * table.rowsPerPage + table.rowsPerPage
							// )
							.map((row, index) => (
								<TableRow
									RenderAccordion={RenderAccordion}
									mode={mode}
									enableRowSelection={enableRowSelection}
									index={index}
									key={row.id}
									columns={columns}
									data={row}
									showSl={showSl}
								/>
							))}
					</TableBody>
				</MUITable>
			</TableContainer>
			<TablePagination />
		</Card>
	);

	return element;
}

export default Table;
