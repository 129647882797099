// import { useEffect } from "custom_exports/all_hooks";
// import { Routes, Route } from "react-router-dom";
// import { Cookies } from "react-cookie";
// import { authActions } from "services/redux/reducers/authSlice";
// import AppCover from "components/covers/AppCover";
// import RequireAuth from "components/common/RequireAuth";
// import LoginPage from "components/pages/LoginPage";
import AppRoutes from "components/routes/AppRoutes";

function App() {
	//--------------------------------- variables ---------------------------------//
	// const currentCountRef = useRef(0);

	// const dispatch = useDispatch();

	//--------------------------------- functions ---------------------------------//

	// useEffect(() => {
	// 	const handleStorageChange = (event) => {
	// 		if (event.key === "persist:userAccessToken") {
	// 			window.location.reload();
	// 		}
	// 	};

	// 	window.addEventListener("storage", handleStorageChange);

	// 	return () => {
	// 		window.removeEventListener("storage", handleStorageChange);
	// 	};
	// }, []);

	//--------------------------------- contents ----------------------------------//
	const content = <AppRoutes />;

	return content;
}

export default App;
