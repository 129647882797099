import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import {
	useEnumerateReducer,
	navigationReducer,
	sideBarReducer,
	// authReducer,
	customerReducer,
	productReducer,
	productCategoryReducer,
	orderReducer,
	cartReducer,
} from "services/redux/reducers/slice";
import { apiMiddleware, apiReducer, apiReducerPath, authReducer } from "./reducers";
import APP from "app";

const rootPersistSettings = {
	key: "root",
	whitelist: [],
	storage: storageSession,
	version: APP.version,
};

const userLoggedInPersistSettings = {
	key: "loggedIn",
	whitelist: ["loggedIn"],
	storage,
};

const rootReducer = combineReducers({
	useEnumerate: useEnumerateReducer,
	[apiReducerPath]: apiReducer,

	sideBar: sideBarReducer,
	navigation: navigationReducer,
	auth: persistReducer(userLoggedInPersistSettings, authReducer),
	customer: customerReducer,
	product: productReducer,
	productCategory: productCategoryReducer,
	order: orderReducer,
	cart: cartReducer,
});

const persistedReducer = persistReducer(rootPersistSettings, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(apiMiddleware),
	devTools: false,
});

const persistor = persistStore(store);

export { persistor };

export default store;
