// Category Drawer

import {
	useState,
	useTheme,
	useMediaQuery,
	useDispatch,
	useSelector,
	useCOrdersMutation,
} from "custom_exports/all_hooks";
import {
	Box,
	Drawer,
	List,
	AppBar,
	Toolbar,
	CircularProgress,
	Typography,
	Button,
	Divider,
	TextField,
} from "custom_exports/material_ui_items";
import {
	cartActions,
	orderActions,
	getCartDrawerShowStatus,
	getProductItemsAsDict,
	getCartItemsAsList,
	// getCustomerItemsAsList,
} from "services/redux/reducers/slice";
import { authSelectors } from "services/redux/reducers";
import { callApi } from "common/api_handlers/api_call_manager";
import CartItem from "components/drawers/cart/CartItem";
import CustomerSelectionListModal from "components/modals/CustomerSelectionListModal";
import Swal from "sweetalert2";
import { THEME } from "app/settings/settings";
import K from "common/keywords";
import C from "common/constants";

function CartDrawer(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const theme = useTheme();
	const isViewingOnMobile = useMediaQuery(theme.breakpoints.down("tablet"));

	// ---- destructuring ---- //
	const { toggleCartDrawer, setCart, resetCart } = cartActions;
	const { resetOrder } = orderActions;

	// ---- assignments ---- //
	const dispatch = useDispatch();
	const [showCustomerSelectionListModal, setShowCustomerSelectionListModal] =
		useState(false);
	const [COrdersApiCaller, COrdersApiResult] = useCOrdersMutation();
	const showCartDrawer = useSelector(getCartDrawerShowStatus);
	const productsDict = useSelector(getProductItemsAsDict);
	const cartItemsList = useSelector(getCartItemsAsList);
	// const customersAsList = useSelector(getCustomerItemsAsList);
	const user = useSelector(authSelectors.getUser);
	const [orderSuggestion, setOrderSuggestion] = useState("");

	// console.log(customersAsList);

	//--------------------------------- functions ---------------------------------//
	// ---- api managers ---- //
	const onApiResponse = ({ apiCallerId, ...response }) => {
		// console.log(response);
		const { data } = response;
		if (apiCallerId === K.COrders) {
			dispatch(resetCart());
			dispatch(resetOrder());
			Swal.fire({
				text: "Your Order Has Been Placed Successfully",
				icon: "success",
				confirmButtonColor: THEME.app.colors.primary.main,
			});
			setOrderSuggestion("");
		}
	};

	const onApiError = ({ apiCallerId, ...error }) => {
		console.log(error);
		if (apiCallerId === K.COrders) {
			Swal.fire({
				text: "Order Placement Failed, Something Went Wrong..",
				icon: "error",
				confirmButtonColor: THEME.app.colors.red.main,
			});
		}
	};

	const apiActions = { onApiResponse, onApiError };

	const callCOrdersApi = (apiInputParams = {}, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.COrders,
			apiCaller: COrdersApiCaller,
			apiInputParams,
			apiActions,
		});
	};
	// ---- handlers ---- //
	const handleOnCartDrawerClose = () => {
		dispatch(toggleCartDrawer({ show: false }));
	};

	const handleOnClick = (id, data) => {
		if (id === K.incrementCartItem) {
			dispatch(setCart([{ itemId: data.id, action: "add", count: 1 }]));
		} else if (id === K.decrementCartItem) {
			dispatch(setCart([{ itemId: data.id, action: "remove", count: 1 }]));
		} else if (id === K.showCustomerSelectionList) {
			setShowCustomerSelectionListModal(true);
		} else if (id === K.placeOrder) {
			if (cartItemsList.length) {
				const orders = {
					orders: {
						items: [
							{
								placed_by_user: {
									id: data?.placedByUserId || null,
								},
								cart: { items: cartItemsList },
								order_suggestion: orderSuggestion,
								notify_customer: data?.notifyCustomer ?? true,
							},
						],
					},
				};
				callCOrdersApi(orders);
			}
		}
	};

	//--------------------------------- contents ----------------------------------//
	const cartItemComponents = cartItemsList.map((cartItem) => {
		let cartItemComponent = null;
		if (cartItem.id in productsDict) {
			const productDetails = productsDict[cartItem.id];
			cartItem = { ...cartItem, ...productDetails };
			cartItemComponent = (
				<CartItem
					key={cartItem.id}
					cartItem={cartItem}
					handleOnClick={handleOnClick}
				/>
			);
		}
		return cartItemComponent;
	});

	const content = (
		<>
			{user?.role !== C.user_roles.customer && (
				<CustomerSelectionListModal
					showModal={showCustomerSelectionListModal}
					setShowModal={setShowCustomerSelectionListModal}
					handleOnClick={handleOnClick}
				/>
			)}
			<Drawer
				anchor="right"
				sx={{
					"& .MuiDrawer-paper": {
						bgcolor: "primary.lighter",
						width: isViewingOnMobile ? 260 : 350,
					},
				}}
				open={showCartDrawer}
				onClose={handleOnCartDrawerClose}
			>
				<AppBar
					position="static"
					sx={{ bgcolor: "primary.lighter", boxShadow: "none" }}
				>
					<Toolbar sx={{ justifyContent: "center" }}>
						<Typography fontWeight={500}>Your Cart</Typography>
					</Toolbar>
				</AppBar>
				<Divider sx={{ borderBottomWidth: 2 }} />
				<Box sx={{ overflowY: "auto", width: "100%", flex: 1 }}>
					<List sx={{ width: "100%" }}>{cartItemComponents}</List>
				</Box>
				<TextField
					value={orderSuggestion}
					sx={{ marginY: 2, marginX: 2 }}
					placeholder="Order Suggestion"
					onChange={(event) => setOrderSuggestion(event.target.value)}
				/>
				<Button
					variant="contained"
					disabled={COrdersApiResult.isLoading}
					sx={{ p: 4 }}
					onClick={() => {
						if (user?.role) {
							user.role === C.user_roles.customer
								? handleOnClick(K.placeOrder)
								: handleOnClick(K.showCustomerSelectionList);
						}
					}}
				>
					{COrdersApiResult.isLoading && <CircularProgress />}
					{!COrdersApiResult.isLoading && "Place Order"}
				</Button>
			</Drawer>
		</>
	);

	return content;
}

export default CartDrawer;
