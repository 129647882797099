// Permissions Based Routing
import { useSelector } from "custom_exports/all_hooks";
import { authSelectors } from "services/redux/reducers";
import C from "common/constants";
import { useRouterPathname } from "hooks";
import NAVIGATION_ITEMS from "app/navigations/navigationItems";
import { Navigate } from "react-router-dom";

function RequirePermission(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //

	// ---- destructuring ---- //
	const { children, renderChildren, userRoles } = props;

	// ---- assignments ---- //
	const loggedInUser = useSelector(authSelectors.getUser);
	const loggedInUserRole = loggedInUser?.role;

	const grantedUserRoles = userRoles?.granted;
	const deniedUserRoles = grantedUserRoles ? null : userRoles?.denied;

	const permissionGranted =
		grantedUserRoles?.includes(loggedInUserRole) ||
		(deniedUserRoles instanceof Array &&
			!deniedUserRoles.includes(loggedInUserRole));

	//--------------------------------- functions ---------------------------------//
	//--------------------------------- contents ----------------------------------//

	const content = permissionGranted ? children : renderChildren || null;

	return content;
}

function RequireRoutePermission(props) {
	//--------------------------------- variables ---------------------------------//
	const pathname = useRouterPathname();
	// ---- destructuring ---- //
	const { children } = props;

	let userRoles = NAVIGATION_ITEMS[pathname]?.permissions?.userRoles;

	//--------------------------------- contents ----------------------------------//
	const content = userRoles ? (
		<RequirePermission
			userRoles={userRoles}
			renderChildren={<Navigate to={"/"} />}
		>
			{children}
		</RequirePermission>
	) : (
		children
	);

	return content;
}
function RequireUserRoleAsCustomer(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { children } = props;

	//--------------------------------- contents ----------------------------------//
	const content = (
		<RequirePermission userRoles={{ granted: [C.user_roles.customer] }}>
			{children}
		</RequirePermission>
	);

	return content;
}

function RequireUserRoleAsNotCustomer(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { children } = props;

	//--------------------------------- contents ----------------------------------//
	const content = (
		<RequirePermission userRoles={{ denied: [C.user_roles.customer] }}>
			{children}
		</RequirePermission>
	);

	return content;
}

function RequireUserRoleAsStaff(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { children } = props;

	//--------------------------------- contents ----------------------------------//
	const content = (
		<RequirePermission userRoles={{ granted: [C.user_roles.staff] }}>
			{children}
		</RequirePermission>
	);

	return content;
}

export {
	RequireUserRoleAsCustomer,
	RequireUserRoleAsNotCustomer,
	RequireUserRoleAsStaff,
	RequireRoutePermission,
};

export default RequirePermission;
