// Navigation Item

import {
	useDispatch,
	useSelector,
	useLocation,
	useEffect,
	useTheme,
} from "custom_exports/all_hooks";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import {
	navigationActions,
	getAppNavigation,
	getLeftSideBar,
	sideBarActions,
} from "services/redux/reducers/slice";
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from "custom_exports/material_ui_items";

const { setAppNavigation } = navigationActions;

function NavigationItem(props) {
	//--------------------------------- variables ---------------------------------//
	const { item, level } = props;

	const { setLeftSideBar } = sideBarActions;

	const theme = useTheme();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const appNavigation = useSelector(getAppNavigation);
	const activeItemId = appNavigation.active;

	const itemTarget = item.newTab ? "_blank" : "_self";
	const listItemProps = {
		component: forwardRef((props, ref) => (
			<Link ref={ref} {...props} to={item.url} target={itemTarget} />
		)),
	};

	const Icon = item.icon;
	const itemIcon = item.icon ? <Icon style={{ fontSize: "1rem" }} /> : false;

	const leftSideBar = useSelector(getLeftSideBar);

	//--------------------------------- functions ---------------------------------//
	useEffect(() => {
		if (pathname.includes(item.url)) {
			dispatch(setAppNavigation({ active: item.id }));
		}
	});

	const handleLeftSideBarToggle = () => {
		let sideBar = { ...leftSideBar };
		sideBar.opened = !sideBar.opened;
		dispatch(setLeftSideBar(sideBar));
	};

	const handleOnClick = (event, { itemId }) => {
		handleLeftSideBarToggle();
	};

	//--------------------------------- contents ----------------------------------//
	const content = (
		<ListItemButton
			{...listItemProps}
			disabled={item.disabled}
			onClick={(event) => handleOnClick(event, { itemId: item.id })}
			selected={item.id === activeItemId}
			sx={{
				zIndex: 1201,
				pl: `${level * 28}px`,
				py: 1,
				"&:hover": {
					bgcolor: "primary.lighter",
				},
				"&.Mui-selected": {
					bgcolor: "primary.lighter",
					borderRight: `3px solid ${theme.palette.primary.main}`,
					"&:hover": {
						bgcolor: "primary.lighter",
					},
				},
			}}
		>
			{itemIcon && (
				<ListItemIcon
					sx={{
						minWidth: 28,
						color:
							item.id === activeItemId ? "primary.main" : "grey.main",
					}}
				>
					{itemIcon}
				</ListItemIcon>
			)}
			<ListItemText
				primary={
					<Typography
						sx={{
							fontWeight: 500,
							// fontSize: "1rem",
							lineHeight: 1.57,
							color:
								item.id === activeItemId
									? "primary.main"
									: "grey.dark",
						}}
					>
						{item.title}
					</Typography>
				}
			/>
		</ListItemButton>
	);

	return content;
}

export default NavigationItem;
