import { useRef, useCallback, useState, useEffect } from "react";
import { useSignInMutation } from "services/api";
import { AuthPageCover } from "components/covers";
import {
	Card,
	alpha,
	Typography,
	Grid,
	Box,
	Button,
	Link,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Backdrop,
	CircularProgress,
	Stack,
} from "custom_exports/material_ui_items";
import EmailForm from "./EmailForm";
import OtpForm from "./OtpForm";
import { useNavigate, useLocation } from "react-router-dom";
import { authActions } from "services/redux/reducers";
import { useDispatch } from "react-redux";

function SignInPage(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //

	// ---- assignments ---- //
	const formRef = useRef();
	const userDataRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [signInApiCaller, signInApiResult] = useSignInMutation();

	const [otp, setOtp] = useState(null);
	const [error, setError] = useState(location.state?.errorMessage || "");
	const [userInactiveMessage, setUserInactiveMessage] = useState("");

	const [isResendOtpDisabled, setIsResendOtpDisabled] = useState(false);
	const [timer, setTimer] = useState(0);

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleForm = useCallback(
		({ type, data }) => {
			if (type === "submit") {
				signInApiCaller({ email: data.email, password: data.password });
				userDataRef.current = { email: data.email };
			}
			setOtp(null);
			setError("");
		},
		[signInApiCaller]
	);

	const verifyOtp = () => {
		setError("");
		if (otp && otp.every((data) => data)) {
			signInApiCaller({
				...userDataRef.current,
				otp: otp.join(""),
			});
		}
	};

	const handleResendClick = () => {
		setError("");
		setIsResendOtpDisabled(true);
		setTimer(60);
		signInApiCaller(userDataRef.current);
	};

	// ---- useEffects ---- //
	useEffect(() => {
		if (signInApiResult.isSuccess) {
			// setOtp(null);
			// setError("");
			if (signInApiResult?.data?.status === "fail") {
				if (signInApiResult.data.data?.user_exists === false) {
					navigate("/auth/sign-up", {
						state: {
							errorMessage: signInApiResult.data.message,
							email: userDataRef.current?.email || "",
						},
					});
					// setError(signInApiResult.data.message);
				} else if (signInApiResult?.data?.data?.user_inactive === true) {
					setUserInactiveMessage(signInApiResult.data.message);
				} else if (otp) {
					setError("Invalid Otp");
				} else {
					setError("Invalid Credentials");
				}
			} else {
				if (signInApiResult.data.data.authenticated === true) {
					dispatch(authActions.setAuth());
				} else {
					// setOtp(["", "", "", "", "", ""]);
				}
			}
			signInApiResult.reset();
		}
	}, [signInApiResult, dispatch, otp, navigate]);

	useEffect(() => {
		let interval = null;

		if (isResendOtpDisabled && timer > 0) {
			// Start countdown
			interval = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);
		} else if (timer === 0) {
			// Enable the link when the timer reaches 0
			setIsResendOtpDisabled(false);
			clearInterval(interval);
		}

		return () => clearInterval(interval);
	}, [isResendOtpDisabled, timer]);

	//--------------------------------- elements ----------------------------------//

	const element = (
		<AuthPageCover>
			<Card
				sx={{
					position: "relative",
					zIndex: 0,
					boxShadow: `0 0 2px 0 ${alpha(
						"#919EAB",
						0.2
					)}, 0 12px 24px -4px ${alpha("#919EAB", 0.12)}`,
					overflow: "visible",
					py: 2,
					px: 4,
				}}
				style={{ borderRadius: 16 }}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{otp ? (
						<>
							{userDataRef.current?.email && (
								<Stack
									sx={{
										flexDirection: { xs: "column", md: "row" },
									}}
									alignItems="center"
									justifyContent="center"
									my={2}
								>
									<Typography>
										{userDataRef.current?.email}
									</Typography>
									<Link
										href="/auth/sign-in"
										sx={{
											fontWeight: "500",
											ml: 1,
											color: "#015bf8",
										}}
									>
										{"Change Email"}
									</Link>
								</Stack>
							)}
							<Typography
								color="#000000"
								variant="caption"
								fontSize="17px"
								sx={{ textAlign: { xs: "center", md: "center" } }}
								fontWeight={"500"}
							>
								{
									"We’ve sent an OTP to your email. Please enter it below to verify your identity"
								}
							</Typography>
						</>
					) : (
						<>
							<Typography
								color="primary"
								variant="h5"
								gutterBottom
								sx={{ fontWeight: "600", color: "#000000" }}
								textAlign="center"
							>
								Sign In
							</Typography>
						</>
					)}
					{otp ? (
						<OtpForm otp={otp} setOtp={setOtp} />
					) : (
						<EmailForm formRef={formRef} handleForm={handleForm} />
					)}
					<Stack
						direction={"row"}
						justifyContent={otp && error ? "space-between" : "flex-end"}
						width={"92%"}
						mt={3}
					>
						{error && otp && (
							<Typography
								variant="h7"
								alignSelf={"center"}
								color="error"
								fontSize={"15px"}
								fontWeight={"600"}
							>
								{error}
							</Typography>
						)}
						{otp && (
							<Typography alignSelf={"flex-end"}>
								<Link
									sx={{
										textDecoration: "none",
										color: isResendOtpDisabled
											? "grey"
											: "black",
										// my: 2,
										cursor: isResendOtpDisabled
											? "default"
											: "pointer",
									}}
									component="button"
									variant="body2"
									onClick={handleResendClick}
									disabled={isResendOtpDisabled}
								>
									{isResendOtpDisabled
										? `Resend OTP in ${timer} seconds`
										: "Resend OTP"}
								</Link>
							</Typography>
						)}
					</Stack>

					{otp ? (
						<Button
							disabled={
								signInApiResult.isLoading ||
								(otp && otp.some((data) => !data))
							}
							fullWidth
							variant="contained"
							sx={{
								mt: 3,
								mb: 2,
								borderRadius: 18,
								// height: 40,
								fontSize: 16,
								fontWeight: 600,
								width: "50%",
								background: "#015bf8",
								color: "#fff",
								"&:hover": {
									bgcolor: "#000000",
								},
							}}
							onClick={verifyOtp}
						>
							{"Sign In"}
						</Button>
					) : (
						<Button
							disabled={signInApiResult.isLoading}
							fullWidth
							variant="contained"
							sx={{
								mt: 3,
								mb: 2,
								borderRadius: 18,
								// height: 40,
								fontSize: 16,
								fontWeight: 600,
								width: "50%",
								background: "#015bf8",
								color: "#fff",
								"&:hover": {
									bgcolor: "#000000",
								},
							}}
							onClick={() => {
								if (formRef.current) {
									formRef.current.submitForm(); // Trigger the submit form method
								}
							}}
						>
							{"Sign In"}
						</Button>
					)}
					{error && !otp && (
						<Typography
							variant="h7"
							alignSelf={"center"}
							color="error"
							fontSize={"14px"}
						>
							{error}
						</Typography>
					)}
					<Grid container justifyContent="center">
						<Grid item>
							<Stack mt={2} direction={"row"}>
								<Typography
									variant="body3"
									// sx={{ fontWeight: "600" }}
								>
									{"New user ? "}
								</Typography>
								<Link
									href="/auth/sign-up"
									variant="body1"
									sx={{
										fontWeight: "600",
										ml: 1,
										color: "#015bf8",
									}}
								>
									{"Sign Up"}
								</Link>
							</Stack>
						</Grid>
					</Grid>
				</Box>
			</Card>

			{/*  */}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={signInApiResult.isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			{/*  */}
			<Dialog
				open={!!userInactiveMessage}
				aria-labelledby="responsive-dialog-title"
				fullWidth
				// maxWidth=""
				sx={{
					"& .MuiPaper-root": {
						borderRadius: "16px", // Rounded corners for modern look
						padding: "12px", // Add padding to make it feel spacious
						backgroundColor: "#f5f5f5", // Light background for modern feel
					},
				}}
			>
				<DialogTitle
					id="responsive-dialog-title"
					sx={{
						textAlign: "center",
						fontWeight: "bold",
						fontSize: "1rem", // Larger text for title
						color: "#333", // Darker text for better contrast
					}}
				>
					Account Activation Pending
				</DialogTitle>
				<DialogContent>
					<DialogContentText textAlign={"justify"}>
						{userInactiveMessage}
					</DialogContentText>
				</DialogContent>

				<DialogActions
					sx={{ justifyContent: "center", paddingBottom: "16px" }}
				>
					<Button
						onClick={() => navigate("/")}
						variant="contained" // Use contained button for modern look
						color="primary" // Use error color for emphasis
						sx={{
							width: "100px",
							borderRadius: "8px", // Modern button with rounded corners
							textTransform: "none", // Remove uppercase text transformation
						}}
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</AuthPageCover>
	);

	return element;
}

export default SignInPage;
