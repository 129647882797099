import { globalActions } from "services/redux/actions";

function logout(params) {
	let { dispatch } = params || {};

	if (dispatch) {
		dispatch(globalActions.logout());
	}
}

export default logout;
