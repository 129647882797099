const API_END_POINTS = {
	// Auth
	signIn: {
		name: "signIn",
		path: "users/auth/sign-in",
		method: "POST",
		additionalInputParams: {},
		defaultInputParams: {},
	},
	signUp: {
		name: "signUp",
		path: "users/auth/sign-up",
		method: "POST",
		additionalInputParams: {},
		defaultInputParams: {},
	},

	// customers
	updateCustomer: {
		name: "updateCustomer",
		path: "customers/update",
		method: "POST",
		additionalInputParams: {},
		defaultInputParams: {},
	},

	//  products category
	deleteProductCategory: {
		name: "deleteProductCategory",
		path: "products/categories/delete",
		method: "POST",
		additionalInputParams: {},
		defaultInputParams: {},
	},
};

export default API_END_POINTS;
