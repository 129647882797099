import { createSlice } from "@reduxjs/toolkit";
import { globalActions } from "../actions";

const initialState = { items: { dict: {}, list: [] } };

const customerSlice = createSlice({
	name: "customer",
	initialState,
	reducers: {
		setCustomer: (state, action) => {
			const itemsAsList = action.payload;
			state.items.list = itemsAsList;
			for (let i = 0; i < itemsAsList.length; i++) {
				const item = { ...itemsAsList[i], listPosition: i };
				state.items.dict[item.id] = item;
			}
		},
		updateCustomer: (state, action) => {
			const item = action.payload;
			if (item.id in state.items.dict) {
				const itemListPosition = state.items.dict[item.id].listPosition;
				state.items.list[itemListPosition] = item;
				state.items.dict[item.id] = {
					...item,
					listPosition: itemListPosition,
				};
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(globalActions.logout, () => initialState);
	},
});

const getCustomerItemsAsDict = (state) => state.customer.items.dict;

const getCustomerItemsAsList = (state) => state.customer.items.list;

export { getCustomerItemsAsDict, getCustomerItemsAsList };

export default customerSlice;
