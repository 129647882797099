// Copyright Block

import { Box, Typography } from "custom_exports/material_ui_items";
import APP from "app";

function CopyRight(props) {
	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box {...props}>
			<Typography
				variant="body2"
				color="grey.light"
				align="center"
				gutterBottom
			>
				{"Copyright © "} {new Date().getFullYear()}
				{". "} {APP.account.id} {". All rights reserved"}
			</Typography>
			<Typography
				variant="body2"
				color="grey.light"
				align="center"
				sx={{ fontSize: 11 }}
			>
				Made In Canada - {APP.version}
			</Typography>
		</Box>
	);

	return content;
}

export default CopyRight;
