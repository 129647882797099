import {
	TableRow as MUITableRow,
	TableCell,
	Checkbox,
	Collapse,
	IconButton,
	Box,
} from "components/common/mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useBoolean } from "hooks";

function getNestedValue(obj, path) {
	return path.split(".").reduce((value, key) => value && value[key], obj);
}

function TableRow(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const {
		columns,
		data,
		showSl,
		index,
		enableRowSelection,
		mode,
		RenderAccordion,
	} = props;

	// ---- assignments ---- //
	const accordionView = useBoolean();
	const isHovered = useBoolean();

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //

	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//
	const element = (
		<>
			<MUITableRow
				sx={{
					// "&:hover": {
					// 	backgroundColor: "#F4F6F8",
					// },
					...(mode === "accordion" && accordionView.value
						? { "& > *": { borderBottom: "unset" } }
						: {}),
					backgroundColor: isHovered.value ? "#F4F6F8" : "inherit",
				}}
				onMouseEnter={isHovered.setTrue}
				onMouseLeave={isHovered.setFalse}
			>
				{enableRowSelection && (
					<TableCell padding="checkbox">
						<Checkbox size="small" />
					</TableCell>
				)}
				{showSl && <TableCell>{index + 1}</TableCell>}
				{columns.map((column, index) => {
					let value = getNestedValue(data, column.key);
					if (column?.cell?.transform?.processor) {
						value = column?.cell?.transform?.processor(value);
					}
					if (column?.cell?.transform?.Renderer) {
						const Renderer = column.cell.transform.Renderer;
						return (
							<TableCell key={index}>
								<Renderer cellData={value} rowData={data} />
							</TableCell>
						);
					}
					return <TableCell key={index}>{value}</TableCell>;
				})}
				{mode === "accordion" && (
					<TableCell>
						<IconButton onClick={accordionView.setToggle}>
							{accordionView.value ? (
								<ExpandLessIcon />
							) : (
								<ExpandMoreIcon />
							)}
						</IconButton>
					</TableCell>
				)}
			</MUITableRow>
			{mode === "accordion" && (
				<MUITableRow
					sx={{
						backgroundColor: isHovered.value ? "#F4F6F8" : "inherit",
						...(accordionView.value
							? { "& > *": { borderBottom: "5px solid grey" } }
							: {}),
					}}
				>
					<TableCell sx={{ p: 0 }} colSpan={columns.length + 1}>
						<Collapse
							in={accordionView.value}
							timeout="auto"
							unmountOnExit
							sx={{ bgcolor: "background.neutral" }}
						>
							<Box sx={{ px: 2, pt: 2, pb: 4 }}>
								<RenderAccordion data={data} />
							</Box>
						</Collapse>
					</TableCell>
				</MUITableRow>
			)}
		</>
	);

	return element;
}

export default TableRow;
