import apiSlice from "services/redux/reducers/apiSlice";
import { BACK_END_API_END_POINTS as AEP } from "app/settings/settings";
import API_END_POINTS from "./end_points/endPoints";

const customersApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		RCustomers: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.customers.RCustomers.url,
				method: AEP.customers.RCustomers.method,
				body: { ...inputParams },
			}),
		}),
		UCustomers: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.customers.UCustomers.url,
				method: AEP.customers.UCustomers.method,
				body: { ...inputParams },
			}),
		}),
		updateCustomer: builder.mutation({
			query: (
				inputParams = API_END_POINTS.updateCustomer.defaultInputParams
			) => ({
				url: API_END_POINTS.updateCustomer.path,
				method: API_END_POINTS.updateCustomer.method,
				body: {
					...inputParams,
					...API_END_POINTS.updateCustomer.additionalInputParams,
				},
			}),
		}),
	}),
});

export const {
	useRCustomersMutation,
	useUCustomersMutation,
	useUpdateCustomerMutation,
} = customersApi;

export default customersApi;
