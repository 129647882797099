// --------- App Specific Keywords --------- //

const APP_KEYWORDS = {
	getUserWithTokens: "getUserWithTokens",
	RUser: "retrieveUser",
	createCustomer: "createCustomer",
	RCustomers: "retrieveCustomers",
	UCustomers: "updateCustomers",
	RProducts: "retrieveProducts",
	RProductCategories: "retrieveProductCategories",
	ROrders: "retrieveOrders",
	COrders: "createOrders",
	UOrders: "updateOrders",
	UOrderStatus: "updateOrderStatus",
};

export default APP_KEYWORDS;
