import { Box, TextField } from "custom_exports/material_ui_items";
import { useState, useRef } from "react";

function OtpForm(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const { otp, setOtp } = props;

	// ---- assignments ---- //

	const inputRefs = useRef([]);

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleChange = (e, index) => {
		const value = e.target.value;

		if (!/^\d$/.test(value) && value !== "") return;

		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		if (value && index < otp.length - 1) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleKeyDown = (e, index) => {
		if (e.key === "Backspace") {
			const newOtp = [...otp];
			newOtp[index] = ""; // Clear the current input

			setOtp(newOtp);

			// Move to the previous input if current is empty and it's not the first input
			if (index > 0 && !newOtp[index]) {
				inputRefs.current[index - 1].focus();
			}
		}
	};

	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//
	const element = (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				gap: { xs: 1, md: 2 },
				mt: 2,
			}}
		>
			{otp.map((data, index) => (
				<TextField
					type="tel"
					key={index}
					value={data}
					onChange={(e) => handleChange(e, index)}
					onKeyDown={(e) => handleKeyDown(e, index)}
					inputRef={(el) => (inputRefs.current[index] = el)}
					inputProps={{
						maxLength: 1,
						style: {
							textAlign: "center",
							fontSize: "24px",
						},
					}}
					sx={{
						"& .MuiOutlinedInput-root": {
							borderRadius: "18px",
							borderColor: "primary.main",
							width: { xs: 40, md: 50 }, // Width for each digit input
							height: { xs: 40, md: 50 },
						},
						// "& .MuiOutlinedInput-notchedOutline": {
						// 	borderColor: "#2196F3", // Custom border color
						// },
						// "&:hover .MuiOutlinedInput-notchedOutline": {
						// 	borderColor: "#1E88E5", // Border color on hover
						// },
						// "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
						// 	borderColor: "#1976D2", // Border color on focus
						// },
					}}
				/>
			))}
		</Box>
	);

	return element;
}

export default OtpForm;
