// Users
import CONSTANTS from "common/constants";
import { MdEmojiPeople, MdPeopleAlt } from "react-icons/md";
import OrdersPageCover from "components/covers/pages/OrdersPageCover";

const USERS = {
	"/users/staffs": {
		id: "/users/staffs",
		title: "Staffs",
		type: "single",
		icon: MdPeopleAlt,
		url: "/users/staffs",
		disabled: true,
		permissions: { userRoles: { denied: [CONSTANTS.user_roles.customer] } },
	},
	"/users/customers": {
		id: "/users/customers",
		path: "/users/customers",
		title: "Customers",
		type: "single",
		icon: MdEmojiPeople,
		url: "/users/customers",
		disabled: false,
		pageCover: OrdersPageCover,
		permissions: { userRoles: { denied: [CONSTANTS.user_roles.customer] } },
	},
};

export default USERS;
