const API_SERVER_MODE = "M";
const VERSION = "1.2.6";
const PRODUCTION = true;
let ACCOUNT_ID = 1;

let apiServer = { domain: window.BACK_END_URL };

if (API_SERVER_MODE === "D") {
	ACCOUNT_ID = 1;
	apiServer = { domain: process.env.REACT_APP_D_DOMAIN };
} else if (API_SERVER_MODE === "T") {
	ACCOUNT_ID = 1;
	apiServer = { domain: process.env.REACT_APP_T_DOMAIN };
}

const CONFIG = {
	apiServer: { ...apiServer, baseUrl: apiServer.domain + "/api/" },
	version: VERSION,
	production: PRODUCTION,
	theme: "default",
	defaultIndexPagePath: "",
	account: { id: ACCOUNT_ID },
};

export default CONFIG;
