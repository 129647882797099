// Combining All Redux Slice Exports

import useEnumerateSlice from "services/redux/reducers/useEnumerateSlice";
import navigationSlice, {
	getAppNavigation,
} from "services/redux/reducers/navigationSlice";
import sideBarSlice, { getLeftSideBar } from "services/redux/reducers/sideBarSlice";
import customerSlice, {
	getCustomerItemsAsDict,
	getCustomerItemsAsList,
} from "services/redux/reducers/customerSlice";
import cartSlice, {
	getCartDrawerShowStatus,
	getCartItemsAsDict,
	getCartItemsAsList,
	getTotalNumberOfCartItems,
} from "services/redux/reducers/cartSlice";
import productSlice, {
	getProductItemsAsDict,
	getProductItemsAsList,
} from "services/redux/reducers/productSlice";
import productCategorySlice, {
	getProductCategoryItemsAsDict,
	getProductCategoryItemsAsList,
	getProductCategoryItemsAsTree,
	getProductCategoriesFilterAsIdList,
	getShowProductsPageProductCategoryDrawer,
} from "services/redux/reducers/productCategorySlice";

import orderSlice, {
	getOrderItemsAsDict,
	getOrderItemsAsList,
	getPagination,
} from "services/redux/reducers/orderSlice";

// useEnumerate slice
const useEnumerateActions = useEnumerateSlice.actions;
const useEnumerateReducer = useEnumerateSlice.reducer;

// navigation slice
const navigationActions = navigationSlice.actions;
const navigationReducer = navigationSlice.reducer;

// sidebar slice
const sideBarActions = sideBarSlice.actions;
const sideBarReducer = sideBarSlice.reducer;

// customer slice
const customerActions = customerSlice.actions;
const customerReducer = customerSlice.reducer;

// product slice
const productActions = productSlice.actions;
const productReducer = productSlice.reducer;

// product category slice
const productCategoryActions = productCategorySlice.actions;
const productCategoryReducer = productCategorySlice.reducer;

// order slice
const orderActions = orderSlice.actions;
const orderReducer = orderSlice.reducer;

// cart slice
const cartActions = cartSlice.actions;
const cartReducer = cartSlice.reducer;

export {
	useEnumerateSlice,
	useEnumerateActions,
	useEnumerateReducer,
	navigationSlice,
	navigationActions,
	navigationReducer,
	getAppNavigation,
	sideBarSlice,
	sideBarActions,
	sideBarReducer,
	getLeftSideBar,
	customerSlice,
	customerActions,
	customerReducer,
	getCustomerItemsAsDict,
	getCustomerItemsAsList,
	productSlice,
	productActions,
	productReducer,
	getProductItemsAsDict,
	getProductItemsAsList,
	productCategorySlice,
	productCategoryActions,
	productCategoryReducer,
	getProductCategoryItemsAsDict,
	getProductCategoryItemsAsList,
	getProductCategoryItemsAsTree,
	getProductCategoriesFilterAsIdList,
	getShowProductsPageProductCategoryDrawer,
	orderSlice,
	orderActions,
	orderReducer,
	getOrderItemsAsDict,
	getOrderItemsAsList,
	cartSlice,
	cartActions,
	cartReducer,
	getCartDrawerShowStatus,
	getCartItemsAsDict,
	getCartItemsAsList,
	getTotalNumberOfCartItems,
	getPagination,
};
