// Users Routing

import CustomersPage from "components/pages/customers/CustomersPage";

const USERS_ROUTES = {
	path: "users",
	children: [{ path: "customers", element: <CustomersPage /> }],
};

export default USERS_ROUTES;
