import { createSlice } from "@reduxjs/toolkit";
import { globalActions } from "../actions";

const initialState = { user: null, loggedIn: false };

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setAuth: (state, action) => {
			if ("user" in (action.payload || {})) {
				state.user = action.payload.user;
			}
			state.loggedIn = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(globalActions.logout, () => initialState);
	},
});

const getUser = (state) => state.auth.user;
const getLoggedIn = (state) => state.auth.loggedIn;

export const authSelectors = { getUser, getLoggedIn };

export default authSlice;
