// Product Card

import { useSelector } from "custom_exports/all_hooks";
import {
	Box,
	Card,
	Fab,
	Tooltip,
	Stack,
	IconButton,
	Typography,
} from "custom_exports/material_ui_items";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { getCartItemsAsDict } from "services/redux/reducers/slice";
import { authSelectors } from "services/redux/reducers";
import Image from "components/custom/image/Image";
import K from "common/keywords";
import { RequireUserRoleAsCustomer } from "components/common/RequirePermission";

function ProductCard(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { handleOnClick: rootHandleOnClick, product } = props;
	const { id, title, image, tags, price } = product;

	// ---- assignments ---- //
	const user = useSelector(authSelectors.getUser);
	const cartItemsDict = useSelector(getCartItemsAsDict);

	const productIsFavourite = user?.profile?.favourite_products?.includes(id);

	//--------------------------------- contents ----------------------------------//
	// const productTags = <Stack></Stack>;

	const productImage = (
		<Box sx={{ position: "relative", p: 1 }}>
			<RequireUserRoleAsCustomer>
				<IconButton
					sx={{ color: "orange.main" }}
					onClick={() =>
						rootHandleOnClick(
							productIsFavourite
								? K.unMarkProductAsFavourite
								: K.markProductAsFavourite,
							{ productId: id }
						)
					}
				>
					{productIsFavourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
				</IconButton>
			</RequireUserRoleAsCustomer>
			<Fab
				color={id in cartItemsDict ? "success" : "warning"}
				size={id in cartItemsDict ? "small" : "medium"}
				className={id in cartItemsDict ? "show-cart" : "add-to-cart-btn"}
				sx={{
					right: 16,
					bottom: 16,
					zIndex: 9,
					opacity: id in cartItemsDict ? 1 : 0,
					position: "absolute",
					transition: (theme) =>
						theme.transitions.create("all", {
							easing: theme.transitions.easing.easeInOut,
							duration: theme.transitions.duration.shorter,
						}),
				}}
				onClick={() =>
					rootHandleOnClick(
						id in cartItemsDict ? K.showCartDrawer : K.addProductToCart,
						{
							id,
						}
					)
				}
			>
				{id in cartItemsDict ? (
					<CheckCircleIcon />
				) : (
					<AddShoppingCartOutlinedIcon />
				)}
			</Fab>

			<Tooltip placement="bottom-end">
				<Image
					alt={title}
					src={image}
					ratio="1/1"
					enableEffect={false}
					sx={{ borderRadius: 1.5 }}
					imageSx={{ objectFit: "contain" }}
				/>
			</Tooltip>
		</Box>
	);

	const productInfo = (
		<Stack spacing={2.5} sx={{ p: 3, pt: 2 }}>
			<Typography color="inherit" variant="subtitle1">
				{title}
			</Typography>
			{Number(price) > 0 && (
				<Stack direction="row" alignItems="center" justifyContent="left">
					<Stack
						direction="row"
						spacing={0.5}
						sx={{ typography: "subtitle1" }}
					>
						{/* <Box
						component="span"
						sx={{
							color: "grey.light",
							textDecoration: "line-through",
						}}
					>
						{"price"}
					</Box> */}
						<Box component="span">{`$ ${price}`}</Box>
					</Stack>
				</Stack>
			)}
		</Stack>
	);

	const content = (
		<Card
			sx={{
				borderRadius: 2,
				// boxShadow: 4,
				"&:hover .add-to-cart-btn": { opacity: 1 },
				width: 200,
			}}
		>
			{/* {productTags} */}
			{productImage}
			{productInfo}
		</Card>
	);

	return content;
}

export default ProductCard;
