import { Grid, Box, Paper } from "custom_exports/material_ui_items";
import { CopyRight, Logo } from "components/common";
import SRC from "custom_exports/all_file_items";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { authSelectors } from "services/redux/reducers";

function AuthPageCover(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initialization ---- //
	// ---- destructuring ---- //
	const { children } = props;

	// ---- assignments ---- //
	const userLoggedIn = useSelector(authSelectors.getLoggedIn);

	//--------------------------------- functions ---------------------------------//
	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//
	let element = (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: `url(${SRC.rethinkBiocleanTruck1})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			/>
			<Grid
				item
				component={Paper}
				square
				elevation={6}
				xs={12}
				sm={8}
				md={5}
				sx={{
					height: "100vh",
					overflowY: "auto",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						my: 3,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						height: "100%",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Logo
							logoName="rethinkBiocleanLogo1"
							alt="rethink-bioclean-logo"
							style={{
								width: "150px",
								height: "90px",
								marginBottom: "10px",
							}}
						/>
						{children}
					</Box>
					<CopyRight sx={{ mt: 4, pb: 4 }} />
				</Box>
			</Grid>
		</Grid>
	);

	if (userLoggedIn) {
		element = <Navigate to="/" replace />;
	}

	return element;
}

export default AuthPageCover;
