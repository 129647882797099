import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP from "app";
import logout from "utils/logout";

const baseQueryWithAuth = fetchBaseQuery({
	baseUrl: APP.apiServer.baseUrl,
	credentials: "include",
});

const baseQueryWithOutAuth = fetchBaseQuery({
	baseUrl: APP.apiServer.baseUrl,
	credentials: "omit",
	// prepareHeaders: (headers) => {
	// 	return headers;
	// },
});

const baseQueryWrapper = async (args, api, extraOptions) => {
	if (args.body) {
		args.body.meta_data = {
			client: {
				app_id: APP.id,
				app_version: APP.version,
				device_platform: APP.platform,
			},
		};
		args.body.account_id = APP.account.id;
	}
	let apiResponse = await baseQueryWithAuth(args, api, extraOptions);

	if (apiResponse?.error?.status === 401) {
		logout({ dispatch: api.dispatch });
	} else if (apiResponse?.error?.status === "FETCH_ERROR") {
		// in future add a error page navigation
	}

	return apiResponse;
};

const apiSlice = createApi({
	baseQuery: baseQueryWrapper,
	endpoints: (builder) => ({}),
});

export default apiSlice;
