import { Table } from "components/common/iui/table";

function DataListingPageBuilder(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const { table } = props;

	// ---- assignments ---- //
	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //

	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//

	const element = <Table {...table} />;

	return element;
}

export default DataListingPageBuilder;
