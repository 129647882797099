// Auth Routing

import { SignInPage, SignUpPage } from "components/pages";

const AUTH_ROUTES = {
	path: "auth",
	children: [
		{ path: "sign-in", element: <SignInPage /> },
		{ path: "sign-up", element: <SignUpPage /> },
	],
};

export default AUTH_ROUTES;
