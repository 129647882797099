import { Formik } from "formik";
import YUP from "utils/yup";
import { Box, TextField } from "custom_exports/material_ui_items";
import { useLocation } from "react-router-dom";

function EmailForm(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const { handleForm, formRef } = props;

	// ---- assignments ---- //
	const location = useLocation();
	const initialValues = { email: location.state?.email || "", password: "" };

	const validationSchema = YUP.object().shape({
		email: YUP.string()
			.email("Provide a valid email")
			.required("Email is required"),

		password: YUP.string().required("Password is required"),
	});

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //

	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//
	const element = (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			innerRef={formRef}
			onSubmit={(userInput) => {
				handleForm({ type: "submit", data: userInput });
			}}
		>
			{({ values, errors, touched, handleBlur, handleChange }) => (
				<Box sx={{ mt: 1, width: "100%" }}>
					<TextField
						id="login-form-email"
						type="email"
						name="email"
						label={errors.email || "Email"}
						autoComplete="email"
						margin="normal"
						fullWidth
						value={values.email}
						error={Boolean(touched.email && errors.email)}
						onBlur={handleBlur}
						onChange={(event) => {
							handleChange(event);
							handleForm({
								type: "change",
								event,
							});
						}}
					/>
					<TextField
						id="login-form-password"
						type="password"
						name="password"
						label={errors.password || "Password"}
						// autoComplete="email"
						margin="normal"
						fullWidth
						value={values.password}
						error={Boolean(touched.password && errors.password)}
						onBlur={handleBlur}
						onChange={(event) => {
							handleChange(event);
							handleForm({
								type: "change",
								event,
							});
						}}
					/>
				</Box>
			)}
		</Formik>
	);

	return element;
}

export default EmailForm;
