// Custom Tree

import "./styles/treeViewStyles.css";
import { useState } from "custom_exports/all_hooks";
import { Tree } from "react-arborist";
import {
	Stack,
	Typography,
	Button,
	IconButton,
	Box,
} from "custom_exports/material_ui_items";
import { MdArrowRight, MdArrowDropDown, MdEco } from "react-icons/md";
import FillFlexParent from "../geometry/FillFlexParent";
import K from "common/keywords";

function Node({ node, style, dragHandle }) {
	//--------------------------------- variables ---------------------------------//
	const nodeHasChildren = node.children?.length > 0;
	const indentSize = Number.parseFloat(`${style.paddingLeft || 0}`);

	//--------------------------------- contents ----------------------------------//
	const nodeLeftIcon = nodeHasChildren ? (
		<IconButton
			component="span"
			sx={{ p: 0 }}
			onClick={(event) => {
				event.stopPropagation();
				node.toggle();
			}}
		>
			{node.isOpen ? <MdArrowDropDown /> : <MdArrowRight />}
		</IconButton>
	) : (
		<MdEco style={{ marginLeft: 5.5 }} />
	);

	const content = (
		<Box style={style} ref={dragHandle}>
			<Button
				variant="text"
				sx={{
					textTransform: "none",
					minWidth: "100%",
					justifyContent: "normal",
					...(node.isFocused && { bgcolor: "primary.lighter" }),
					...(node.isSelected && {
						bgcolor: "primary.light",
						color: "white",
						"&:hover": {
							bgcolor: "primary.light",
						},
					}),
				}}
			>
				<Stack direction="row" spacing={1} alignItems="center">
					{nodeLeftIcon}
					<Typography
						sx={{ width: 700 }}
						color={node.isSelected ? "white" : "grey.dark"}
						textAlign={"left"}
					>
						{node.data.title}
					</Typography>
				</Stack>
			</Button>
		</Box>
	);

	return content;
}

function TreeView(props) {
	//--------------------------------- variables ---------------------------------//
	const {
		data = [],
		idPropertyName = "id",
		childrenPropertyName = "children",
		enableEdit = false,
		enableDrag = false,
		enableDrop = false,
		enableMultipleSelection = false,
		expandAll = false,
		nodeHeight = 40,
		treeRef,
		handleOnClick: rootHandleOnClick,
	} = props;

	// ---- assignments ---- //
	// const [selectedItemId, setSelectedItemId] = useState("");

	//--------------------------------- contents ----------------------------------//
	const content = (
		<FillFlexParent>
			{(dimensions) => (
				<Tree
					ref={treeRef}
					{...dimensions}
					// selection={selectedItemId}
					// initialData={data}
					data={data}
					idAccessor={idPropertyName}
					childrenAccessor={childrenPropertyName}
					openByDefault={expandAll}
					disableMultiSelection={!enableMultipleSelection}
					disableEdit={!enableEdit}
					disableDrag={!enableDrag}
					disableDrop={!enableDrop}
					rowHeight={nodeHeight}
					rowClassName="tree-view-tree-row"
					className="tree-view-container"
					onSelect={(e) => {
						if (e?.length > 0) {
							// setSelectedItemId(e[0].id);
							rootHandleOnClick(K.selectProductCategory, {
								id: Number(e[0].id),
							});
						}
					}}
				>
					{Node}
				</Tree>
			)}
		</FillFlexParent>
	);

	return content;
}

export default TreeView;
