// User Bar With Brief List DropDown

import { useSelector } from "custom_exports/all_hooks";
import { authSelectors } from "services/redux/reducers";
import {
	Box,
	ButtonBase,
	Stack,
	Avatar,
	Typography,
} from "custom_exports/material_ui_items";
import SRC from "custom_exports/all_file_items";

function User() {
	//--------------------------------- variables ---------------------------------//
	const user = useSelector(authSelectors.getUser);

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box sx={{ flexShrink: 0, ml: 2.75 }}>
			<ButtonBase
				sx={{
					p: 0.3,
					borderRadius: 1,
					"&:hover": { bgcolor: "primary.lighter" },
				}}
			>
				<Stack
					direction="row"
					spacing={2}
					alignItems="center"
					sx={{ p: 0.1, pl: 0.3, pr: 0.3 }}
				>
					<Typography variant="subtitle2">
						{user?.profile?.business_name || user?.email}
					</Typography>
					{/* <Avatar
						alt="user"
						// src={SRC.rethinkBiocleanTruck1}
						sx={{ width: 36, height: 36 }}
					/> */}
				</Stack>
			</ButtonBase>
		</Box>
	);

	return content;
}

export default User;
