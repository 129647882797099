import { createSlice } from "@reduxjs/toolkit";
import { globalActions } from "../actions";

const initialState = { history: {} };

const useEnumerateSlice = createSlice({
	name: "useEnumerate",
	initialState,
	reducers: {
		setUseEnumerateHistory: (state, action) => {
			const newHistory = action.payload;
			const currentHistory = state.history;
			state.history = { ...currentHistory, ...newHistory };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(globalActions.logout, () => initialState);
	},
});

// const getProductItemsAsDict = (state) => state.product.items.dict;

// const getProductItemsAsList = (state) => state.product.items.list;

// export {};

export default useEnumerateSlice;
