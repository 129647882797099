import { useLocation } from "react-router-dom";
import { useMemo } from "react";

function useRouterPathname() {
    //--------------------------------- variables ---------------------------------//
    // ---- initializations ---- //
    const { pathname } = useLocation();

    // ---- assignments ---- //

    //--------------------------------- functions ---------------------------------//
    // ---- handlers ---- //

    // ---- useEffects ---- //

    //--------------------------------- elements ----------------------------------//
    // const element = null;

    return useMemo(() => pathname, [pathname]);
}

export default useRouterPathname;
