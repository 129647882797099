import { useEffect, useState } from "react";
import {
	Box,
	Card,
	alpha,
	Backdrop,
	CircularProgress,
	Button,
	Typography,
	Stack,
} from "components/common/mui";
import { useRCustomersMutation } from "custom_exports/all_hooks";
import { DataListingPageBuilder } from "components/common/builders";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerItemsAsList } from "services/redux/reducers/slice";
import { customerActions } from "services/redux/reducers/slice";
import { useUpdateCustomerMutation } from "services/api";
import Swal from "sweetalert2";
import { THEME } from "app/settings/settings";
import { format, parseISO } from "date-fns";

function CustomersPage() {
	//--------------------------------- variables ---------------------------------//
	// ---- initialization ---- //
	// ---- destructuring ---- //
	const dispatch = useDispatch();

	// ---- assignments ---- //
	// const [selectedTab, setSelectedTab] = useState(0);
	const [RCustomersApiCaller, RCustomersApiResult] = useRCustomersMutation();
	const [updateCustomerApiCaller, updateCustomerApiResult] =
		useUpdateCustomerMutation();

	const customers = [...useSelector(getCustomerItemsAsList)].sort((a, b) => {
		if (a.is_active === b.is_active) {
			return 0;
		}
		return a.is_active ? 1 : -1;
	});

	//--------------------------------- functions ---------------------------------//

	// ---- useEffects ---- //
	useEffect(() => {
		RCustomersApiCaller();
	}, [RCustomersApiCaller]);

	useEffect(() => {
		if (RCustomersApiResult.isSuccess) {
			if (RCustomersApiResult?.data?.status === "success") {
				let data = RCustomersApiResult?.data?.data;
				dispatch(customerActions.setCustomer(data));
			}
			RCustomersApiResult.reset();
		}
	}, [RCustomersApiResult, dispatch]);

	useEffect(() => {
		if (updateCustomerApiResult.isSuccess) {
			if (updateCustomerApiResult?.data?.status === "success") {
				if ("meta_data" in updateCustomerApiResult?.data) {
					let meta_data = updateCustomerApiResult?.data?.meta_data;
					if (meta_data?.action_type === "reset_password") {
						Swal.fire({
							text: "Login credentials sent to the customer.",
							icon: "success",
							confirmButtonColor: THEME.app.colors.primary.main,
						});
					}
				} else {
					let data = updateCustomerApiResult?.data?.data;
					dispatch(customerActions.updateCustomer(data));
				}
			} else {
				Swal.fire({
					text: "Something went wrong",
					icon: "error",
					confirmButtonColor: THEME.app.colors.red.main,
				});
			}
			updateCustomerApiResult.reset();
		}
		if (updateCustomerApiResult.isError) {
			Swal.fire({
				text: "Something went wrong",
				icon: "error",
				confirmButtonColor: THEME.app.colors.red.main,
			});
			updateCustomerApiResult.reset();
		}
	}, [updateCustomerApiResult, dispatch]);

	//--------------------------------- elements ----------------------------------//

	const element = (
		<Box sx={{ bgcolor: "background.paper", width: "100%" }}>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					updateCustomerApiResult.isLoading ||
					RCustomersApiResult.isLoading
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box sx={{ m: 0, width: "100%" }}>
				{customers.length > 0 ? (
					<DataListingPageBuilder
						table={{
							data: customers,
							enableRowSelection: false,
							mode: "accordion",
							RenderAccordion: ({ data }) => {
								return (
									<Box>
										<Typography fontWeight="bold" variant="h6">
											Additional Details
										</Typography>

										<Stack
											direction="column"
											spacing={1}
											mt={2}
										>
											<Stack
												direction="row"
												alignItems="center"
											>
												<Typography
													sx={{
														color: "grey",
														width: 120,
													}}
												>
													Business Email
												</Typography>
												<Typography
													sx={{ color: "grey" }}
													mx={5}
												>
													:
												</Typography>
												<Typography>
													{data.profile.business_email}
												</Typography>
											</Stack>
											<Stack
												direction="row"
												alignItems="center"
											>
												<Typography
													sx={{
														color: "grey",
														width: 120,
													}}
												>
													Approved By
												</Typography>
												<Typography
													sx={{ color: "grey" }}
													mx={5}
												>
													:
												</Typography>
												<Typography>
													{data.activated_by_user_name}
												</Typography>
											</Stack>
											{data.activated_date_time && (
												<Stack
													direction="row"
													alignItems="center"
												>
													<Typography
														sx={{
															color: "grey",
															width: 120,
														}}
													>
														Approved On
													</Typography>
													<Typography
														sx={{ color: "grey" }}
														mx={5}
													>
														:
													</Typography>
													<Typography>
														{format(
															parseISO(
																data.activated_date_time
															),
															"yyyy-MM-dd"
														)}
													</Typography>
												</Stack>
											)}
											{data.is_active && (
												<Stack
													direction="row"
													alignItems="center"
												>
													<Typography
														sx={{
															color: "dodgerblue",
															cursor: "pointer",
															textDecoration:
																"underline",
														}}
														onClick={() => {
															updateCustomerApiCaller(
																{
																	id: data.id,
																	action_type:
																		"reset_password",
																}
															);
														}}
													>
														Reset Password
													</Typography>
												</Stack>
											)}
										</Stack>
									</Box>
								);
							},
							columns: [
								{
									key: "profile.business_name",
									header: { label: "Name" },
								},
								{
									key: "email",
									header: { label: "Email" },
								},
								// {
								// 	key: "profile.business_email",
								// 	header: { label: "Business Email" },
								// },
								{
									key: "profile.business_phone",
									header: { label: "Phone" },
								},
								{
									key: "profile.business_address",
									header: { label: "Address" },
									cell: {
										style: { width: "200px" },
									},
								},
								// {
								// 	key: "activated_by_user",
								// 	header: { label: "Approved By" },
								// 	cell: {
								// 		style: { width: "200px" },
								// 	},
								// },
								{
									key: "created_on",
									header: { label: "Sign Up Date" },

									cell: {
										transform: {
											renderer: <></>,
											processor: (data) =>
												format(
													parseISO(data),
													"yyyy-MM-dd"
												),
										},
									},
								},
								{
									key: "id",
									header: { label: "Status" },
									cell: {
										style: {},
										transform: {
											Renderer: ({ cellData, rowData }) => (
												<>
													{rowData.is_active ? (
														<Typography>
															Active
														</Typography>
													) : (
														<Button
															variant="contained"
															onClick={() => {
																updateCustomerApiCaller(
																	{
																		id: cellData,
																		action_type:
																			"activate_customer",
																	}
																);
															}}
														>
															Approve
														</Button>
													)}
												</>
											),
										},
									},
								},
							],
						}}
					/>
				) : (
					<>
						<Typography textAlign={"center"} mt={18}>
							No Customers
						</Typography>
					</>
				)}
			</Box>
		</Box>
	);

	return element;
}

export default CustomersPage;
