import authSlice, { authSelectors } from "./authSlice";
import apiSlice from "./apiSlice";

// api slice
const apiMiddleware = apiSlice.middleware;
const apiReducer = apiSlice.reducer;
const apiReducerPath = apiSlice.reducerPath;

// auth slice
const authActions = authSlice.actions;
const authReducer = authSlice.reducer;

export {
	apiSlice,
	apiMiddleware,
	apiReducer,
	apiReducerPath,
	authSlice,
	authActions,
	authReducer,
	authSelectors,
};
