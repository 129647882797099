import apiSlice from "services/redux/reducers/apiSlice";
import AEP from "./end_points/endPoints";

const authApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		signIn: builder.mutation({
			query: (inputParams = AEP.signIn.defaultInputParams) => ({
				url: AEP.signIn.path,
				method: AEP.signIn.method,
				body: {
					...inputParams,
					...AEP.signIn.additionalInputParams,
				},
			}),
		}),
		signUp: builder.mutation({
			query: (inputParams = AEP.signUp.defaultInputParams) => ({
				url: AEP.signUp.path,
				method: AEP.signUp.method,
				body: {
					...inputParams,
					...AEP.signUp.additionalInputParams,
				},
			}),
		}),
	}),
});

export const { useSignInMutation, useSignUpMutation } = authApi;

export default authApi;
