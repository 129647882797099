import {
	TableHead as MUITableHead,
	TableRow,
	TableCell,
	Checkbox,
	TableSortLabel,
	Box,
} from "components/common/mui";

const visuallyHidden = {
	border: 0,
	margin: -1,
	padding: 0,
	width: "1px",
	height: "1px",
	overflow: "hidden",
	position: "absolute",
	whiteSpace: "nowrap",
	clip: "rect(0 0 0 0)",
};

function TableHead(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const {
		order,
		orderBy,
		rowCount = 0,
		headLabel,
		numSelected = 0,
		onSort,
		onSelectAllRows,
		sx,
		enableRowSelection,
	} = props;

	// ---- assignments ---- //

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //

	// ---- useEffects ---- //

	//--------------------------------- elements ----------------------------------//
	const element = (
		<MUITableHead sx={sx}>
			<TableRow
				sx={{
					backgroundColor: "#F4F6F8",
				}}
			>
				{onSelectAllRows && enableRowSelection && (
					<TableCell padding="checkbox">
						<Checkbox
							size="small"
							indeterminate={!!numSelected && numSelected < rowCount}
							checked={!!rowCount && numSelected === rowCount}
							onChange={(event) =>
								onSelectAllRows(event.target.checked)
							}
						/>
					</TableCell>
				)}

				{headLabel.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align || "left"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width,
							minWidth: headCell.minWidth,
							...headCell.style,
						}}
					>
						{onSort ? (
							<TableSortLabel
								hideSortIcon
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={() => onSort(headCell.id)}
							>
								{headCell.label}

								{orderBy === headCell.id ? (
									<Box sx={{ ...visuallyHidden }}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							headCell.label
						)}
					</TableCell>
				))}
			</TableRow>
		</MUITableHead>
	);

	return element;
}

export default TableHead;
