// Products Page

import {
	useState,
	useRef,
	useRProductsMutation,
	useRProductCategoriesMutation,
	useRCustomersMutation,
	useUCustomersMutation,
	useDispatch,
	useSelector,
	useCallback,
	useEffect,
	useTheme,
	useMediaQuery,
	useOutletContext,
} from "custom_exports/all_hooks";
import { Box } from "custom_exports/material_ui_items";
import ProductCategoryDrawer from "components/drawers/ProductCategoryDrawer";
import ProductsCardView from "components/pages/products/cards/ProductsCardView";
import { callApi } from "common/api_handlers/api_call_manager";
import {
	customerActions,
	cartActions,
	productActions,
	getProductItemsAsList,
	productCategoryActions,
	getProductCategoryItemsAsTree,
	getProductCategoryItemsAsList,
	getProductCategoriesFilterAsIdList,
	getShowProductsPageProductCategoryDrawer,
	getCustomerItemsAsList,
} from "services/redux/reducers/slice";
import { authSelectors, authActions } from "services/redux/reducers";
import K from "common/keywords";
import C from "common/constants";

function ProductsPage() {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const isInitialRenderRef = useRef(true);
	const theme = useTheme();
	const isViewingOnMobile = useMediaQuery(theme.breakpoints.down("tablet"));

	// ---- destructuring ---- //
	const { showFavouriteProducts } = useOutletContext();
	const { setCart, toggleCartDrawer } = cartActions;
	const { setProduct } = productActions;
	const {
		setProductCategory,
		setProductCategoriesFilter,
		setShowProductsPageProductCategoryDrawer,
	} = productCategoryActions;
	const { setCustomer } = customerActions;

	// ---- assignments ---- //
	const dispatch = useDispatch();
	const productCategoryFilterTreeRef = useRef();
	const [RProductsErrorMessage, setRProductsErrorMessage] = useState(null);
	const [RProductsApiCaller, RProductsApiResult] = useRProductsMutation();
	const [RProductCategoriesApiCaller, RProductCategoriesApiResult] =
		useRProductCategoriesMutation();
	const [RCustomersApiCaller, RCustomersApiResult] = useRCustomersMutation();
	const [UCustomersApiCaller, UCustomersApiResult] = useUCustomersMutation();
	const user = useSelector(authSelectors.getUser);
	const productsAsList = useSelector(getProductItemsAsList);
	const productCategoriesAsTree = useSelector(getProductCategoryItemsAsTree);
	const productCategoriesAsList = useSelector(getProductCategoryItemsAsList);
	const productCategoriesFilterAsIdList = useSelector(
		getProductCategoriesFilterAsIdList
	);
	const showProductCategoryDrawer = useSelector(
		getShowProductsPageProductCategoryDrawer
	);
	const customersAsList = useSelector(getCustomerItemsAsList);

	//--------------------------------- functions ---------------------------------//
	// ---- api managers ---- //
	const onApiResponse = ({ apiCallerId, ...response }) => {
		console.log(response);
		const { data } = response;
		if (apiCallerId === K.RProducts) {
			dispatch(setProduct(data.products.items));
		} else if (apiCallerId === K.RProductCategories) {
			dispatch(setProductCategory(data.product_categories.items));
		} else if (apiCallerId === K.RCustomers) {
			dispatch(setCustomer(data));
		} else if (apiCallerId === K.UCustomers) {
			dispatch(authActions.setAuth({ user: data.customers.item }));
		} else {
			console.log("onApiResponse is not defined for" + apiCallerId);
		}
	};

	const onApiError = ({ apiCallerId, ...error }) => {
		console.log(error);
		if (apiCallerId === K.RProducts) {
			setRProductsErrorMessage(error.errorMessage);
		}
	};

	const apiActions = { onApiResponse, onApiError };

	const callRProductsApi = (apiInputParams = {}, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.RProducts,
			apiCaller: RProductsApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const callRProductCategoriesApi = (apiInputParams = {}, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.RProductCategories,
			apiCaller: RProductCategoriesApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const callRCustomersApi = (apiInputParams = {}, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.RCustomers,
			apiCaller: RCustomersApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const callUCustomersApi = (apiInputParams = {}, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.UCustomers,
			apiCaller: UCustomersApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	// useEnumerate([{ func: callRProductsApi }], "infinite", {
	// 	callerId: K.RProducts,
	// 	testCondition: isInitialRenderRef.current, //&& !productsAsList.length,
	// });

	// useEnumerate([{ func: callRProductCategoriesApi }], "infinite", {
	// 	callerId: K.RProductCategories,
	// 	testCondition: isInitialRenderRef.current,
	// });

	// useEnumerate([{ func: callRCustomersApi }], 1, {
	// 	callerId: K.RCustomers,
	// 	testCondition: isInitialRenderRef.current,
	// });

	// ---- handlers ---- //
	const handleOnClick = (id, data) => {
		if (id === K.addProductToCart) {
			dispatch(setCart([{ itemId: data.id, action: "add" }]));
		} else if (id === K.showCartDrawer) {
			dispatch(toggleCartDrawer({ show: true }));
		} else if (id === K.selectProductCategory) {
			dispatch(setProductCategoriesFilter([data.id]));
			isViewingOnMobile &&
				dispatch(setShowProductsPageProductCategoryDrawer(false));
		} else if (id === K.resetProductCategoriesFilter) {
			dispatch(setProductCategoriesFilter([]));
			isViewingOnMobile &&
				dispatch(setShowProductsPageProductCategoryDrawer(false));
		} else if (id === K.markProductAsFavourite) {
			let inputParams = {
				method: "update_customer_favourite_products",
				action: "add",
				products: [data.productId],
			};
			callUCustomersApi(inputParams);
		} else if (id === K.unMarkProductAsFavourite) {
			let inputParams = {
				method: "update_customer_favourite_products",
				action: "remove",
				products: [data.productId],
			};
			callUCustomersApi(inputParams);
		} else {
			console.log("unknown click id " + id);
		}
	};

	const getFilteredProductsAsList = useCallback(() => {
		let filteredProductsAsList = productsAsList;
		if (showFavouriteProducts) {
			filteredProductsAsList = productsAsList.filter((item) =>
				user?.profile?.favourite_products.includes(item.id)
			);
		} else if (productCategoriesFilterAsIdList.length > 0) {
			filteredProductsAsList = productsAsList.filter((item) => {
				return productCategoriesFilterAsIdList.some(
					(id) => item.categories.indexOf(id) >= 0
				);
			});
		}
		return filteredProductsAsList;
	}, [
		productsAsList,
		productCategoriesFilterAsIdList,
		showFavouriteProducts,
		user,
	]);

	// ---- useEffects ---- //
	useEffect(() => {
		const productCategoryFilterTree = productCategoryFilterTreeRef.current;

		if (
			productCategoriesFilterAsIdList.length <= 0 &&
			productCategoryFilterTree
		) {
			productCategoryFilterTree.deselectAll();
		}
	}, [productCategoriesFilterAsIdList]);

	useEffect(() => {
		if (!isViewingOnMobile) {
			dispatch(setShowProductsPageProductCategoryDrawer(true));
		}
	}, [dispatch, setShowProductsPageProductCategoryDrawer, isViewingOnMobile]);

	useEffect(() => {
		if (productsAsList.length <= 0) {
			callRProductsApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (productCategoriesAsList.length <= 0) {
			callRProductCategoriesApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			user?.role &&
			user.role !== C.user_roles.customer &&
			customersAsList.length <= 0
		) {
			callRCustomersApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box sx={{ display: showProductCategoryDrawer ? "flex" : "block" }}>
			<ProductCategoryDrawer
				productCategories={productCategoriesAsTree}
				treeRef={productCategoryFilterTreeRef}
				handleOnClick={handleOnClick}
			/>
			<ProductsCardView
				products={getFilteredProductsAsList()}
				handleOnClick={handleOnClick}
			/>
		</Box>
	);

	//------------------------------------ end ------------------------------------//
	isInitialRenderRef.current = false;

	return content;
}

export default ProductsPage;
