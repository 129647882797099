import { useState, useEffect } from "react";
import { Stack, Typography, IconButton } from "components/common/mui";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function Pagination(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const {
		totalRecords = 0,
		totalPages = 1,
		currentPageNumber = 1,
		currentDataLength = 0,
		pageSize = 0,
		onPageChange,
		dataName = "Records",
		style: sx = {},
	} = props;

	// ---- assignments ---- //
	const [page, setPage] = useState(currentPageNumber);

	const startRecord = (page - 1) * pageSize + 1;
	const endRecord = startRecord + currentDataLength - 1;

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleNext = () => {
		if (page < totalPages) {
			onPageChange(page + 1);
		}
	};

	const handlePrev = () => {
		if (page > 1) {
			onPageChange(page - 1);
		}
	};

	// ---- useEffects ---- //
	useEffect(() => {
		setPage(currentPageNumber);
	}, [currentPageNumber]);

	//--------------------------------- elements ----------------------------------//
	return (
		<Stack
			spacing={2}
			direction="row"
			alignItems="center"
			// justifyContent="flex-end"
			{...sx}
		>
			<IconButton
				onClick={handlePrev}
				disabled={page <= 1}
				sx={{
					color: page > 1 ? "text.primary" : "text.disabled",
					"&:disabled": {
						color: "text.disabled",
					},
				}}
			>
				<ChevronLeftIcon />
			</IconButton>
			<Typography variant="body2">
				{startRecord}-{endRecord} of {totalRecords} {dataName}
			</Typography>

			<IconButton
				onClick={handleNext}
				disabled={page >= totalPages}
				sx={{
					color: page < totalPages ? "text.primary" : "text.disabled",
					"&:disabled": {
						color: "text.disabled",
					},
				}}
			>
				<ChevronRightIcon />
			</IconButton>
		</Stack>
	);
}

export default Pagination;
