//--------------------------------- API Settings ----------------------------------//

const BASE_BACK_END_URL = window.BACK_END_URL;

const BACK_END_API_URL = BASE_BACK_END_URL + "/api/";

const BACK_END_API_END_POINTS = {
	users: {
		getUserWithTokens: {
			url: "users/retrieve_user_with_tokens/",
			method: "POST",
		},
		getUserRefreshedTokens: {
			url: "users/retrieve_user_refreshed_tokens/",
			method: "POST",
		},
		RUser: {
			url: "users/retrieve_user",
			method: "POST",
		},
	},
	customers: {
		RCustomers: {
			url: "customers/retrieve-bulk",
			method: "POST",
		},
		UCustomers: {
			url: "customers/update_customers",
			method: "POST",
		},
	},
	products: {
		RProducts: {
			url: "products/retrieve_products",
			method: "POST",
		},
		RProductCategories: {
			url: "products/categories/retrieve_product_categories",
			method: "POST",
		},
	},
	orders: {
		ROrders: {
			url: "orders/retrieve-bulk",
			method: "POST",
			default_order_by: ["-created_on"],
		},
		COrders: {
			url: "orders/create_orders",
			method: "POST",
		},
		UOrders: {
			url: "orders/update_orders",
			method: "POST",
		},
	},
};

// API Keys
// const GOOGLE_MAPS_API_KEY = "";

export { BACK_END_API_URL, BACK_END_API_END_POINTS };
