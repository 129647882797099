/**
 * @file Api Call Manager
 * @author Jeril Jose - 7777
 * @version 1.0
 * @function callApi
 */

import { processApiResponseError } from "common/error_handlers/api_error_manager";
import { STATUS_CODES as SC } from "common/constants";

/**
 * Call api and do actions provided
 * @param {object} apiParams Api related params
 * @param {object} params Extra params
 * @returns Api call result
 */
async function callApi(apiParams, params = {}) {
	let { apiCallerId = "", apiCaller, apiInputParams, apiActions } = apiParams;

	let result = { apiCallerId, response: null, error: null };

	let { states, refs } = params;
	let { setIsLoading, setResponse, setError } = states || {};
	let { responseRef, errorRef, errorMessageRef } = refs || {};

	let { onApiResponse, onApiError } = apiActions || {};

	setIsLoading && setIsLoading(true);

	const handleError = (error) => {
		result.error = processApiResponseError(apiCallerId, error);
		let refinedError = { ...result.error, apiCallerId };
		responseRef && (responseRef.current = null);
		errorRef && (errorRef.current = refinedError);
		errorMessageRef && (errorMessageRef.current = refinedError.errorMessage);
		onApiError && onApiError(refinedError);
		setError && setError(refinedError);
	};

	try {
		result.response = await apiCaller(apiInputParams).unwrap();
		let response = { ...result.response, apiCallerId };

		if (
			response?.status?.code === SC.SUCCESS.OK ||
			response?.status?.toLowerCase() === "success"
		) {
			errorRef && (errorRef.current = null);
			errorMessageRef && (errorMessageRef.current = null);
			responseRef && (responseRef.current = response);
			onApiResponse && onApiResponse(response);
			setResponse && setResponse(response);
		} else {
			handleError(response);
		}
	} catch (error) {
		handleError(error);
	}

	setIsLoading && setIsLoading(false);

	return result;
}

export { callApi };
