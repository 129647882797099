function TablePagination(props) {
    //--------------------------------- variables ---------------------------------//
    // ---- initializations ---- //

    // ---- assignments ---- //

    //--------------------------------- functions ---------------------------------//
    // ---- handlers ---- //

    // ---- useEffects ---- //

    //--------------------------------- elements ----------------------------------//
    const element = null;

    return element;
}

export default TablePagination;
