import { useSelector, useState, useMemo } from "custom_exports/all_hooks";
import {
	Box,
	Button,
	Stack,
	ListSubheader,
	TextField,
	InputAdornment,
	Modal,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	MenuItem,
	FormControlLabel,
	Checkbox,
	Typography,
} from "custom_exports/material_ui_items";
import { getCustomerItemsAsList } from "services/redux/reducers/slice";
import K from "common/keywords";
import SearchIcon from "@mui/icons-material/Search";
import { search } from "common/helpers";

function CustomerSelectionListModal(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	// ---- destructuring ---- //
	const { showModal, setShowModal, handleOnClick: rootHandleOnClick } = props;

	// ---- assignments ---- //
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const customers = useSelector(getCustomerItemsAsList).filter(
		(customer) => customer.is_active
	);
	const [searchText, setSearchText] = useState("");
	const displayedCustomers = useMemo(
		() => search(customers, searchText, "profile.business_name"),
		[searchText, customers]
	);
	const [notifyCustomer, setNotifyCustomer] = useState(false);

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleOnCustomerSelectionChange = (event) => {
		const {
			target: { value },
		} = event;

		setSelectedCustomerId(value);
	};

	const handleOnClick = (id, data) => {
		if (id === K.placeOrder) {
			setShowModal(false);
			rootHandleOnClick(K.placeOrder, {
				placedByUserId: selectedCustomerId,
				notifyCustomer,
			});
		} else if (id === K.hideCustomerSelectionList) {
			setShowModal(false);
		}
	};

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Modal open={showModal}>
			<Box
				sx={{
					position: "absolute",
					top: "30%",
					left: "50%",
					transform: "translate(-50%, -30%)",
					width: 400,
					bgcolor: "white",
					p: 4,
					pb: 2,
					borderRadius: 3,
				}}
			>
				<FormControl sx={{ width: "100%" }}>
					<InputLabel>select a customer</InputLabel>
					<Select
						MenuProps={{ autoFocus: false }}
						value={selectedCustomerId}
						input={<OutlinedInput label="select a customer" />}
						onChange={handleOnCustomerSelectionChange}
						onClose={() => setSearchText("")}
					>
						{/* TextField is put into ListSubheader so that it doesn't
                        act as a selectable item in the menu
                        i.e. we can click the TextField without triggering any selection.
                        */}
						<ListSubheader>
							<TextField
								size="small"
								// Autofocus on textfield
								autoFocus
								placeholder="search customer"
								fullWidth
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
								onChange={(e) => setSearchText(e.target.value)}
								onKeyDown={(e) => {
									if (e.key !== "Escape") {
										// Prevents autoselecting item while typing (default Select behaviour)
										e.stopPropagation();
									}
								}}
							/>
						</ListSubheader>
						{displayedCustomers.map((customer) => (
							<MenuItem key={customer.id} value={customer.id}>
								{customer.profile.business_name || customer.email}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControlLabel
					style={{ marginTop: 10 }}
					control={
						<Checkbox
							checked={notifyCustomer}
							onChange={(event) =>
								setNotifyCustomer(event.target.checked)
							}
							style={{
								paddingTop: 0,
								paddingBottom: 0,
								paddingRight: 0,
								transform: "scale(0.7)",
							}}
						/>
					}
					label={
						<Typography fontSize={12}>
							Send notification to customer
						</Typography>
					}
				/>
				<Stack direction="row" justifyContent="flex-end" mt={3}>
					<Button
						color={"error"}
						onClick={() => handleOnClick(K.hideCustomerSelectionList)}
					>
						cancel
					</Button>
					<Button
						disabled={selectedCustomerId ? false : true}
						onClick={() => handleOnClick(K.placeOrder)}
					>
						ok
					</Button>
				</Stack>
			</Box>
		</Modal>
	);

	return content;
}

export default CustomerSelectionListModal;
