import { useState, useCallback } from "react";

function useBoolean(defaultValue) {
    //--------------------------------- variables ---------------------------------//
    // ---- initializations ---- //

    // ---- assignments ---- //
    const [value, setValue] = useState(!!defaultValue);

    const setTrue = useCallback(() => {
        setValue(true);
    }, []);

    const setFalse = useCallback(() => {
        setValue(false);
    }, []);

    const setToggle = useCallback(() => {
        setValue((prev) => !prev);
    }, []);

    //--------------------------------- functions ---------------------------------//
    // ---- handlers ---- //

    // ---- useEffects ---- //

    //--------------------------------- elements ----------------------------------//
    // const element = null;

    return {
        value,
        setTrue,
        setFalse,
        setToggle,
        // setValue,
    };
}

export default useBoolean;
